import { render, staticRenderFns } from "./MySwipper.vue?vue&type=template&id=a0381808&scoped=true"
import script from "./MySwipper.vue?vue&type=script&lang=js"
export * from "./MySwipper.vue?vue&type=script&lang=js"
import style0 from "./MySwipper.vue?vue&type=style&index=0&id=a0381808&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a0381808",
  null
  
)

export default component.exports