import {post} from '../utils/http.js'
//口罩售卖机实时订单
export function MaskTrafficking(){
    return post('https://kz.51xhkj.com/waapi/public/getOrderList')
}
// Share towel
//共享毛巾实时订单
export function ShareTowel(data){
    return post('https://towel.wrlsw.com/waapi/public/getOrderList',data)
}
//湿厕纸巾机实时订单
export function Wipe(data){
    return post('https://szj.86xhkj.com/waapi/public/getOrderList',data)
}
// 有泡泡车实时订单
// Bagged environmenta
export function BaggedEnvironmenta(data){
    return post('https://ppj.handaiwulian.com/waapi/public/getOrderList',data)
}
//共享充电线实施订单
//Shared charging 
export function SharedCharging(){
    return post('https://vm.51xhkj.com/waapi/public/getRechargeOrderList')
}
//有袋环保袋实时订单
// youdai environmenta
export function YoudaiEEnvironmenta(data){
    return post('https://youdai.wrlsw.com/waapi/public/getOrderList',data)
}
//汉代环保袋实时订单
// Hanbag Environmental
export function HanbagEnvironmental(data){
    return post('https://hd.handaiwulian.com/waapi/public/getOrderList',data)
}
//酒店售货机实时订单
//HotelOrder
export function HotelOrder(data){
    return post('https://vm.51xhkj.com/waapi/public/getOrderList',data)
}
//共享隔空无线充实时订单
//Air isolation
export function AirIsolation(data){
    return post('https://xh.xhwxc.com/waapi/public/getOrderList',data)
}