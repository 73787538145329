<template>
  <div id="screenView">
    <header class="top">
      <h1>大数据可视化平台</h1>
      <div class="showTime"></div>
    </header>
    <section class="content">
      <div class="column">
        <my-swipper title="酒店售货机实时订单" :tableDate="hoteDate" height="300" boxHeiHght="2.8">
        </my-swipper>
        <my-swipper title="履带车实时订单" :tableDate="TrackedDate" height="300" boxHeiHght="2.8">
        </my-swipper>
        <!-- <my-swipper title="共享毛巾机实时订单" :tableDate="ShareTowelDate" height="300" boxHeiHght="2.8">
        </my-swipper> -->
        <!-- <my-swipper title="共享充电线实时订单" :tableDate="shardDate">
        </my-swipper> -->
        <my-swipper title="口罩售卖机实时订单" :tableDate="tableDate">
        </my-swipper>
      </div>

      <div class="column">
        <my-map
          :users="users"
          :sumMachineData="sumMachineData"
          :sumOrderData="sumOrderData"
          :sumMoneyData="sumMoneyData"
        ></my-map>
      </div>

      <div class="column">
        <my-swipper title="汉骑士共享泡泡车" :tableDate="baggedDate" height="300" boxHeiHght="2.8">
        </my-swipper>
        <my-swipper title="汉袋环保袋实时订单" :tableDate="hanbagDate" height="300" boxHeiHght="2.8">
        </my-swipper>
        <!-- <my-swipper title="汉袋环保袋实时订单" :tableDate="hanbagDate">
        </my-swipper> -->
        <my-swipper title="共享隔空无线充实时订单" :tableDate="airDdte">
        </my-swipper>
      </div>
    </section>
  </div>
</template>

<script>
import MyMap from "./components/MyMap.vue";
import MySwipper from "./components/MySwipper.vue";
import {
  MaskTrafficking,
  BaggedEnvironmenta,
  AirIsolation,
  YoudaiEEnvironmenta,
  HotelOrder,
  SharedCharging,
  ShareTowel,
  Wipe,
} from "./apis/Menu.js";
export default {
  components: {
    MyMap,
    MySwipper,
  },
  data() {
    return {
      option7: {}, // 地图数据
      users: [
        { id: 0, title: "销售总额", count: "191343379.68" },
        { id: 1, title: "销售订单数", count: "131151258" },
        { id: 2, title: "设备总数", count: "658584" },
      ], // 职场大数据统计
      // 共享毛巾参数
      list: {
        end_time: "",
        lenth: 30,
        start_time: "",
      },
      //口罩机
      tableDate: [],
      // 有袋环保袋实时订单参数
      baggedList: {
        end_time: "",
        goods_name: "",
        hotel_name: "",
        lenth: 30,
        start_time: "",
        // limit:30,
      },
      //共享泡泡车
      baggedDate: [],
      TrackedDate:[],//履带车实时订单

      //共享隔空无线充实时订单
      airDdte: [],
      // 汉代环保袋实时订单
      hanbagDate: [],
      //酒店售货机实时订单
      hotelList: {
        lenth: 10,
        limit: 10,
      },
      hoteDate: [],
      //共享充电线实施订单
      shardDate: [],
      // 共享毛巾实时订单
      ShareTowelDate: [],
      //湿厕纸实时订单
      wipDate:[],
      // 销售数据总数量
      sumMachineData: 0,
      // 小手订单
      sumOrderData: 0,
      //销售总价
      sumMoneyData: 0,
      refreshData: null,
    };
  },
  mounted() {
    document.querySelector(".showTime").innerHTML = this.getYMD();
    this.refreshData = setInterval(() => {
      // this.ShareTowelWay();
      this.BaggedTrackedDateWay()
      this.WipeWay();
      this.MaskTraffickingWay();
      this.BaggedEnvironmentaWay();
      this.AirIsolationWay();
      this.HanbagEnvironmentalWay();
      this.HotelOrderWay();
      // this.SharedChargingWay();
    }, 1000 * 60 * 30);

    this.$nextTick(() => {
      // this.ShareTowelWay();
      this.BaggedTrackedDateWay()
      this.WipeWay();
      this.MaskTraffickingWay();
      this.BaggedEnvironmentaWay();
      this.AirIsolationWay();
      this.HanbagEnvironmentalWay();
      this.HotelOrderWay();
      // this.SharedChargingWay();
    });
  },
  //生命周期若已设置定时器，需清空定时器beforeDestroy()
  beforeDestroy() {
    clearInterval(this.refreshData);
    this.refreshData = null;
  },
  methods: {
    async WipeWay() {
      let res = await Wipe(this.list);
      if (res.code == 1) {
        this.$store.commit("towelWay", res.data);
        this.wipDate = res.data.list;
        this.getData(res);
      }
    },
    async ShareTowelWay() {
      let res = await ShareTowel(this.list);
      if (res.code == 1) {
        // this.$store.commit("towelWay", res.data);
        this.ShareTowelDate = res.data.list;
        this.getData(res);
      }
    },
    async MaskTraffickingWay() {
      let res = await MaskTrafficking();
      if (res.code == 1) {
        this.tableDate = res.data.list;
        this.getData(res);
      }
    },
    async BaggedTrackedDateWay() {
      let data={
        ...this.baggedList,
        device_type:"0xd2"
      }
      let res = await BaggedEnvironmenta(data);
      if (res.code == 1) {
        this.TrackedDate = res.data.list;
        this.getData(res);
      }
    },
    async BaggedEnvironmentaWay() {
      let res = await BaggedEnvironmenta(this.baggedList);
      if (res.code == 1) {
        this.baggedDate = res.data.list;
        this.getData(res);
      }
    },
    async AirIsolationWay() {
      let res = await AirIsolation(this.list);
      if (res.code == 1) {
        this.airDdte = res.data.list;
        this.getData(res);
      }
    },
    async HanbagEnvironmentalWay() {
      let res = await YoudaiEEnvironmenta(this.baggedList);
      if (res.code == 1) {
        this.hanbagDate = res.data.list;
        this.getData(res);
      }
    },
    async HotelOrderWay() {
      let res = await HotelOrder(this.hotelList);
      if (res.code == 1) {
        this.hoteDate = res.data.list;
        this.getData(res);
      }
    },
    async SharedChargingWay() {
      let res = await SharedCharging();
      if (res.code == 1) {
        this.shardDate = res.data.list;
        this.getData(res);
      }
    },
    getData(res) {
      this.sumMachineData = this.sumMachineData + res.data.count_machine;
      this.sumOrderData = this.sumOrderData + res.data.count_order;
      this.sumMoneyData = this.sumMoneyData + res.data.sum_money;
    },
    getYMD() {
      let myDate = new Date();
      let myYear = myDate.getFullYear();
      let myMonth = myDate.getMonth() + 1;
      let myToday = myDate.getDate();
      myMonth = myMonth > 9 ? myMonth : "0" + myMonth;
      myToday = myToday > 9 ? myToday : "0" + myToday;
      let nowDate = myYear + "-" + myMonth + "-" + myToday;
      return nowDate;
    },
  },
};
</script>

<style lang="scss" scoped>
#screenView {
  padding: 0px 48px;
  box-sizing: border-box;
  .top {
    position: relative;
    height: 86px;
    h1 {
      font-size: 0.575rem;
      color: #00b7ee;
      text-align: center;
      line-height: 1.7rem;
    }
    .showTime {
      position: absolute;
      top: 0;
      right: 0.375rem;
      line-height: 0.9375rem;
      font-size: 0.35rem;
      color: rgba(255, 255, 255, 0.7);
    }
  }
  .content {
    min-width: 1024px;
    max-width: 1920px;
    // padding: 0.125rem 0.125rem 0;
    margin-top: 0.3rem;
    display: flex;
    .column {
      width: 545px;
      &:nth-child(2) {
       width: 641px;
        margin: 0 47px;
        overflow: hidden;
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  html {
    font-size: 42px !important;
  }
}
@media screen and (min-width: 1920px) {
  html {
    font-size: 80px !important;
  }
}
</style>